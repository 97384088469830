import React from 'react';
import { Parallax } from 'react-parallax';
import './styles.scss';
import StripesWhite from '../../assets/stripes_white.svg';
import SectionTitleBlueBackground from '../../assets/section_title_blue_bg.svg';
import isMobile from '../../utils/isMobile';

const SectionTitle = (props) => {
  const { title, style } = props;

  const getBaseStyle = (percentage, SectionTitleBlueBackground) => {
    let animationValue = percentage * 100;
    const minAnimValue = -60;
    animationValue += minAnimValue;
    const maxValue = isMobile() ? -22 : 0;
    if (animationValue <= maxValue) {
      return {
        transition: 'all 0.2s 0s linear',
        backgroundPosition: `center ${animationValue}px`,
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${SectionTitleBlueBackground})`,
      };
    }

    return {
      transition: 'all 0.2s 0s linear',
      backgroundPosition: `center ${maxValue}px`,
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${SectionTitleBlueBackground})`,
    };
  };

  return (
    <div className="section-title" style={style}>
      <Parallax
        renderLayer={(percentage) => (
          <div
            className="blue-background"
            style={getBaseStyle(percentage, SectionTitleBlueBackground)}
          />
        )}
      />

      <div className="title-wrapper">
        <img className="icon" alt="icon" src={StripesWhite} />

        <span className="title">{title}</span>
      </div>
    </div>
  );
};

export default SectionTitle;
