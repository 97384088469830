import React, { useState } from 'react';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import CookieIcon from '../../assets/cookie_icon.svg';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import useLocalStorage from '../../hooks/useLocalStorage';
import './styles.scss';

const CookieBar = ({ data }) => {
  const { message, acceptButton, closeButton } = data?.frontmatter;

  const [hidden, setHidden] = useState(false);
  const [cookiesAccepted, setCookiesAccepted] = useLocalStorage(
    'pipe_cookies_accepted',
    false,
  );

  if (cookiesAccepted) {
    return null;
  }

  if (hidden) {
    return null;
  }

  return (
    <div className="cookie-bar">
      <ContentWrapper style={{ paddingTop: '0', paddingBottom: '0' }}>
        <div className="cookie-bar-content">
          <div className="cookie-bar-left">
            <img src={CookieIcon} alt="Cookie Icon" />
            <div className="cookie-text">
              <span dangerouslySetInnerHTML={{ __html: message }} />
            </div>
          </div>
          <div className="cookie-bar-right">
            <PrimaryButton
              size="xxs"
              text={acceptButton}
              onClick={() => setCookiesAccepted(true)}
            />
            <button
              className="close-button"
              type="button"
              onClick={() => setHidden(true)}
            >
              {closeButton}
            </button>
          </div>
        </div>
      </ContentWrapper>
    </div>
  );
};

export default CookieBar;
