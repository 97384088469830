import React from 'react';
import { Link } from 'gatsby';
import './styles.scss';
import ArrowIcon from '../../assets/arrow_up_black.svg';
import CoFinanced from '../../assets/co-financed.png';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import Section from '../Section/Section';
import googleAnalitycsEvents from '../../utils/googleAnalitycsEvents';

const Footer = ({ footer, onClickScroll }) => {
  const {
    copyright,
    logo,
    followus,
    getInTouch,
    useful_links,
    description,
    projectLabel,
    termsLabel,
    privacyLabel,
  } = footer.frontmatter;

  return (
    <footer>
      <Section>
        <ContentWrapper>
          <div className="footer-grid">
            <div className="footer-logo">
              <img src={logo.image} alt="PipeCodes Logo" />
            </div>
            <div className="footer-title useful-links">
              <span>{useful_links.title}</span>
            </div>
            <div className="footer-title get-in-touch">
              <span>{getInTouch.title}</span>
            </div>
            <div className="footer-title follow-us">
              <span>{followus.title}</span>
            </div>
            <div className="footer-description">
              <p>{description}</p>
            </div>
            <div className="footer-links">
              <ul>
                {useful_links.menu.map((elem, i) => (
                  <li key={i.toString()}>
                    <Link
                      to={elem.link}
                      target={elem.openNewTab ? '_blank' : ''}
                    >
                      {elem.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div
              className="footer-contacts"
              dangerouslySetInnerHTML={{ __html: getInTouch.body }}
            />{' '}
            <div className="footer-social">
              {followus.socialmedia.map((elem, i) => (
                <Link
                  key={i.toString()}
                  to={elem.link}
                  target="_blank"
                  onClick={() => {
                    googleAnalitycsEvents('click', {
                      event_category: `footer_social_clicked_${
                        i === 0 ? 'linkedin' : 'instagram'
                      }`,
                    });
                  }}
                >
                  <img src={elem.logo} alt={elem.link} />
                </Link>
              ))}
            </div>
          </div>
        </ContentWrapper>
      </Section>
      <div className="footer-financed">
        <img src={CoFinanced} alt="Co-financed" />
        <p>
          <a
            href="/upload/Pub-Ficha_projeto_FEDER_Pipecodes-Site.pdf"
            target="_blank"
          >
            {projectLabel} 1
          </a>
          <a
            href="/upload/ficha_projeto_ALT20-05-3559-FSE-000041.pdf"
            target="_blank"
          >
            {projectLabel} 2
          </a>
        </p>
      </div>
      <div className="footer-bottom">
        <div className="footer-bottom-content">
          <button
            type="button"
            className="btn-backtotop"
            onClick={onClickScroll}
          >
            <img src={ArrowIcon} alt="Back to the top" />
          </button>
          <span>
            {copyright}
            &nbsp; &nbsp; &nbsp;
            <Link
              to="/terms-conditions"
              target="_blank"
              onClick={() => {
                googleAnalitycsEvents('click', {
                  event_category: `terms_footer_clicked`,
                });
              }}
            >
              {termsLabel}
            </Link>
            &nbsp; | &nbsp;
            <Link
              to="/privacy-policy"
              target="_blank"
              onClick={() => {
                googleAnalitycsEvents('click', {
                  event_category: `privacy_footer_clicked`,
                });
              }}
            >
              {privacyLabel}
            </Link>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
