import React from 'react';

import './styles.scss';

const ContentWrapper = ({ children, style }) => (
  <div className="content-wrapper" style={style}>
    {children}
  </div>
);

export default ContentWrapper;
