import React, { useRef } from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import '../styles';

const Layout = ({ showHeaderAfterScroll, children, header, footer, slug }) => {
  const mainRef = useRef();

  const scrollHandler = () => {
    mainRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  return (
    <div className="layout">
      <Header
        showAfterScroll={showHeaderAfterScroll}
        header={header}
        slug={slug}
      />
      <main ref={mainRef}>{children}</main>
      <Footer footer={footer} onClickScroll={scrollHandler} />
    </div>
  );
};

export default Layout;
