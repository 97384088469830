import { kebabCase } from 'lodash';

export default (language, slug) => {
  if (language === 'pt') {
    return kebabCase(slug).length === 0
      ? `/pt/${window.location.search}${
          window.location.hash ? `${window.location.hash}` : ''
        }`
      : `/pt/${slug.replace('/', '')}${
          window.location.search ? `/${window.location.search}` : ''
        }${window.location.hash ? `${window.location.hash}` : ''}`;
  }

  return kebabCase(slug).length === 0
    ? `${window.location.search ? `/${window.location.search}/` : '/'}`
    : `/${slug}${window.location.search ? `/${window.location.search}/` : '/'}`;
};
