import React, { useState } from 'react';
import { useScroll } from '../../hooks/useScroll';
import Navigation from '../Navigation/Navigation';
import Backdrop from '../Backdrop/Backdrop';
import './styles.scss';

const Header = ({ showAfterScroll, header, slug }) => {
  const { scrollY } = useScroll(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  return (
    <header
      className={`header-fixed ${
        showAfterScroll && scrollY < 80 ? 'hidden' : ''
      } ${mobileOpen && 'mobile-expanded'}`}
    >
      <div className="nav-wrapper">
        <Navigation
          onMobileOpen={() => setMobileOpen((prevState) => !prevState)}
          isFixed
          header={header}
          slug={slug}
        />
      </div>
      {mobileOpen && (
        <>
          <Backdrop onClick={() => setMobileOpen(false)} />
          <div className="mobile-nav">
            <Navigation
              onMobileOpen={() => setMobileOpen(false)}
              isMobile
              header={header}
              slug={slug}
            />
          </div>
        </>
      )}
    </header>
  );
};

export default Header;
