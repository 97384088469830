import React from 'react';
import { Link, navigate } from 'gatsby';

import LanguagePicker from '../LanguagePicker/LanguagePicker';
import googleAnalitycsEvents from '../../utils/googleAnalitycsEvents';

const Navigation = ({ isFixed, isMobile, onMobileOpen, header, slug }) => {
  const { logo, logoFixed, menuItems } = header?.frontmatter;

  const menuItemClick = (link, openNewTab) => {
    if (openNewTab) {
      if (typeof window !== 'undefined') {
        window.open(link, '_blank').focus();
      }
    } else {
      navigate(link);

      if (isMobile) {
        onMobileOpen();
      }
    }
  };

  return (
    <>
      {!isMobile && (
        <div>
          {!isFixed && (
            <Link to="/">
              <img
                src={logo.image}
                alt="PipeCodes Logo"
                className="header-logo"
              />
            </Link>
          )}
          {isFixed && (
            <Link to="/">
              <img
                src={logoFixed.image}
                alt="PipeCodes Logo"
                className="header-logo"
              />
            </Link>
          )}
        </div>
      )}
      <nav>
        <ul>
          {menuItems.map((elem, i) => (
            <li key={i.toString()}>
              <div
                aria-hidden="true"
                onKeyDown={() => {
                  menuItemClick(elem.linkUrl, elem.openNewTab);
                  googleAnalitycsEvents('click', {
                    event_category: `header_menu_clicked_${elem.label.toLocaleLowerCase()}`,
                  });
                }}
                onClick={() => {
                  menuItemClick(elem.linkUrl, elem.openNewTab);
                  googleAnalitycsEvents('click', {
                    event_category: `header_menu_clicked_${elem.label.toLocaleLowerCase()}`,
                  });
                }}
              >
                {elem.label}
              </div>
            </li>
          ))}
          {/* <li>
            <button>
              {!isFixed && (
                <img src={SearchWhiteIcon} alt="White Search Icon" />
              )}
              {isFixed && <img src={SearchIcon} alt="Search Icon" />}
            </button>
          </li> */}
          <li>
            <LanguagePicker slug={slug} />
          </li>
          {!isMobile && (
            <li className="hide-desktop">
              <button
                className="toogle-mobile-menu"
                type="button"
                onClick={onMobileOpen}
              >
                <div className="open-mobile-icon">
                  <div />
                  <div />
                  <div />
                </div>
              </button>
            </li>
          )}
        </ul>
      </nav>
    </>
  );
};

export default Navigation;
