/**
 * useScroll React custom hook
 * Usage:
 *    const { scrollX, scrollY, scrollDirection } = useScroll();
 */

import { useState, useEffect } from 'react';

export function useScroll() {
  if (typeof document === 'undefined') {
    return {
      scrollY: 0,
      scrollX: 0,
      scrollDirection: undefined,
    };
  }
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [bodyOffset, setBodyOffset] = useState(
    document.body.getBoundingClientRect(),
  );
  const [scrollY, setScrollY] = useState(bodyOffset.top);
  const [scrollX, setScrollX] = useState(bodyOffset.left);
  const [scrollDirection, setScrollDirection] = useState();

  const listener = () => {
    const offset = document.body.getBoundingClientRect();
    setBodyOffset(offset);
    setScrollY(-offset.top);
    setScrollX(offset.left);
    setScrollDirection(lastScrollTop > -offset.top ? 'down' : 'up');
    setLastScrollTop(-offset.top);
  };

  useEffect(() => {
    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('scroll', listener);
    };
  });

  return {
    scrollY,
    scrollX,
    scrollDirection,
  };
}
