import { navigate } from 'gatsby';
import React, { useMemo, useEffect } from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';
import slugHandler from '../../utils/slugHandler';

const LANGUAGES = [
  { code: 'en', label: 'EN' },
  { code: 'pt', label: 'PT' },
];

const LanguagePicker = ({ slug }) => {
  const [preferredLanguage, setPreferredLanguage] =
    useLocalStorage('preferred_language');

  const arrSlug = useMemo(
    () => slug?.split('/').filter((el) => el !== ''),
    [slug],
  );

  const navigateTo = (language) => {
    let tempSlug = arrSlug[arrSlug.length - 1];

    if (LANGUAGES.some((lang) => lang.code === tempSlug)) {
      tempSlug = '/';
    }

    const newUrl = slugHandler(language, tempSlug);

    if (slug !== newUrl) {
      navigate(newUrl);
    }
  };

  useEffect(() => {
    if (preferredLanguage) {
      navigateTo(preferredLanguage);
    } else {
      setPreferredLanguage(
        navigator.language?.toLowerCase() === 'pt-pt' ? 'pt' : 'en',
      );
    }
  }, [preferredLanguage, slug]);

  const handleChange = (value) => {
    setPreferredLanguage(value);

    navigateTo(value);
  };

  return (
    <select
      value={preferredLanguage}
      onChange={(e) => handleChange(e.target.value)}
    >
      {LANGUAGES.map((lang) => (
        <option key={lang.code} value={lang.code}>
          {lang.label}
        </option>
      ))}
    </select>
  );
};

export default LanguagePicker;
